/* eslint-disable no-param-reassign */
import Api from '../utils/axios';
import getTokenData from './get-token-data';

const defaultVehicle = {
  id: '',
  price: '',
  registration: '',
  status: 'editing',
  stock: '',
  vin: '',
  carjamRes: {},
};
const yearList = Array.from(Array(Number(new Date().getFullYear()) + 1 - 1944), (_, i) => ({
  id: i,
  label: (i + 1945).toString(),
})).reverse();
const crushList = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' },
];

class VehicleApi {
  constructor() {
    this.Axios = Api;
  }

  getPurchasedVehicle() {
    return this.Promise.resolve({ ...defaultVehicle, id: Math.random() * new Date().getMilliseconds() });
  }

  getCarjamLatestDetails({ rego, vin }) {
    return this.Axios.get(`vehicle/detail?rego=${encodeURIComponent(rego)}&vin=${encodeURIComponent(vin)}`);
  }

  async getVehicleDetails(currentVehicle, from = null) {
    const response = {};
    let carjamRes = currentVehicle?.carjamRes;
    await this.Axios.get('vehicle/color').then((res) => {
      response.color =
        res?.data && res?.data?.filter((obj) => obj.ColorType === carjamRes?.main_colour).length > 0
          ? res?.data
          : ((carjamRes = { ...carjamRes, isMainColorFound: false }), res?.data);
    });
    await this.Axios.get('vehicle/fuelType').then((res) => {
      response.fuelType =
        res?.data && res?.data?.filter((obj) => obj.FuelType === carjamRes?.fuel_type).length > 0
          ? res?.data
          : ((carjamRes = { ...carjamRes, isMainColorFound: false }), res?.data);
    });
    await this.Axios.get('vehicle/bodyStyle').then((res) => {
      response.bodyStyle = res?.data;
    });
    await this.Axios.get('vehicle/type').then((res) => {
      response.vehicleType =
        res?.data && res?.data?.filter((obj) => obj.VehicleType === carjamRes?.vehicle_type).length > 0
          ? res?.data
          : ((carjamRes = { ...carjamRes, isVehicleTypeFound: false }), res?.data);
    });
    await this.Axios.get('location?type=Vehicle').then((res) => {
      response.location = res?.data?.Data;
      response.vehicleLocation = res?.data?.Data;
    });
    if (from !== 'searchVehical') {
      await this.Axios.get('vehicle/countryOrigin').then((res) => {
        response.countryOrigin =
          res?.data && res?.data?.filter((obj) => obj.CountryName === carjamRes?.country_of_origin).length > 0
            ? res?.data
            : ((carjamRes = { ...carjamRes, isCountryOfOriginFound: false }), res?.data);
      });

      await this.Axios.get('vehicle/purpose').then((res) => {
        response.purpose = res?.data;
      });
    }
    await this.Axios.get('vpm/vehicleMaster').then((res) => {
      const { make, model, subModel } = res?.data || {};
      response.make =
        make && make.filter((obj) => obj.Make === carjamRes?.make).length > 0
          ? make
          : ((carjamRes = { ...carjamRes, isMakeFound: false }), make);
      response.model =
        model && model.filter((obj) => obj.Make === carjamRes?.make && obj.Model === carjamRes?.model).length > 0
          ? model
          : ((carjamRes = { ...carjamRes, isModelFound: false }), model);
      response.subModel =
        subModel &&
        subModel.filter((obj) => obj.Make === carjamRes?.make && obj.SubModel === carjamRes?.subModel).length > 0
          ? subModel
          : ((carjamRes = { ...carjamRes, isModelFound: false }), subModel);
    });
    return { vehicleDetails: { ...response, year: yearList, crush: crushList }, carjamRes };
  }

  async postVehicleImageApi(url, obj) {
    return this.Axios.post(url, obj, {
      transformRequest: [
        (data, headers) => {
          delete headers.authorization;
          delete headers.Authorization;
          return data;
        },
      ],
    });
  }

  async postVehicleImageInstance(obj) {
    return this.Axios.post('vehicle/image', obj);
  }

  async postVehicleDetails(obj) {
    const { userIdO } = getTokenData();
    obj.user_id = userIdO;
    return this.Axios.post('vehicle', obj);
  }

  async putVehicle(obj) {
    const { userIdO } = getTokenData();
    obj.user_id = userIdO;
    return this.Axios.put('vehicle', obj);
  }

  async searchVehicleDetails(obj) {
    return new Promise((resolve) => {
      this.Axios.post('vehicle/search', obj).then((res) => {
        resolve({ vehicles: res?.data || [] });
      });
    });
  }

  async searchEcoVehicleDetails(obj) {
    return this.Axios.post('ess/vehicle', obj);
  }

  async deleteVehicle(data) {
    return this.Axios.delete('vehicle', { data });
  }

  async getVehicleImages(stockId) {
    return this.Axios.get(`vehicle/image?stock_id=${stockId}`);
  }

  async getAuctionImage(vin) {
    return this.Axios.get(`vehicle/auctionImage/?vin=${vin}`);
  }

  async deleteVehicleImages(data) {
    return this.Axios.delete('vehicle/image', { data });
  }

  async postVehicleCheck(data) {
    return this.Axios.get(`vehicle/check?vin=${data?.vin}&rego=${data?.rego}`);
  }

  async putVehicleCrushChange(data) {
    return this.Axios.put('vehicle/crush', data);
  }

  async ecoVehicleDetails(id, tanentID) {
    return this.Axios.get(`ess/vehicleId/?tenant_id=${tanentID}&stock_id=${id}`);
  }

  async ecoVehicleSoldParts(id, tanentID) {
    return this.Axios.get(`ess/vehicle/soldPart?tenant_id=${tanentID}&stock_id=${id}`);
  }

  async postPartRequest(data) {
    return this.Axios.post('ess/vehicle/request', data);
  }

  async postStockTake(data) {
    return this.Axios.put('vehicle/stockTake', data);
  }

  async postVehiclePartQuickSell(data) {
    return this.Axios.post('quickSale/vehiclePart', data);
  }

  async getVehicleStats(stockId) {
    return this.Axios.get(`vehicle/stats?stock_id=${stockId}`);
  }

  async getWebsite(stockId) {
    return this.Axios.get(`woocommerce/vehicle?stock_id=${stockId}`);
  }

  async postWebsite(obj) {
    return this.Axios.post('woocommerce/vehicle', obj);
  }

  async putWebsite(obj) {
    return this.Axios.put('woocommerce/vehicle', obj);
  }

  async deleteWebsite(woocommerceStockd, woocommerceProductId, stockId) {
    return this.Axios.delete(
      `woocommerce/vehicle?woocommerce_stock_id=${woocommerceStockd}&woocommerce_product_id=${woocommerceProductId}&stock_id=${stockId}`,
    );
  }

  async getTradeMeCategory() {
    return this.Axios.get('tradeMe/category');
  }

  async getTradeMeCategoryDetails(categoryId) {
    return this.Axios.get(`tradeMe/category/detail?category_id=${encodeURIComponent(categoryId)}`);
  }

  async postTradeMe(payload) {
    return this.Axios.post('tradeMe', payload);
  }

  async getTradeMe(id) {
    return this.Axios.get(`tradeMe?type=vehicle&id=${id}`);
  }

  async getVehicleHistory(id) {
    return this.Axios.get(`history?type=VEHICLE&id=${id}`);
  }
}

export default new VehicleApi();
